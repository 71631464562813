<template>
  <div class="mt-0-px pb-0" v-if="isExtern != null ? !isExtern : false">
    <div id="startseite">
      <div class="logo">
        <img src="https://arvea-dev.batix.ch/pic/17CB79561E9_181x63.png" />
      </div>

      <!--
      <div class="sidebar">
        <div></div>
        <div></div>
        <div></div>
      </div>
	  -->
      <div class="universeContainer mt-5">
        <universe-overview />
      </div>

      <!--
		<div class="textblock">
		  <h1>
			ARVEA Universe <br />
			Calculator
		  </h1>
		  <p>
			Arvea Investments AG is a Swiss fintech investment advisory firm using
			<br />
			proprietary non-linear models. Arvea Investments AG was founded to
			deliver <br />
			innovative and unique financial investment products to meet the needs
			of <br />
			investors in this changing market environment.
		  </p>
		  <div class="row icons" v-if="angemeldeterMitarbeiter">
			<div class="col-3">
			  <div class="calculate">
				<img
				  @click="routeCalculator"
				  src="https://arvea-dev.batix.ch/pic/184143F0A55_130x130.png"
				  title="Calculator"
				/>
			  </div>
			</div>
			<div class="col-3">
			  <div class="options">
				<img
				  @click="routeOptions"
				  src="https://arvea-dev.batix.ch/pic/184143F418A_130x130.png"
				  title="Options"
				/>
			  </div>
			</div>
			<div class="col-3">
			  <div class="etf">
				<img
				  @click="routeETF"
				  src="https://arvea-dev.batix.ch/pic/184143F5508_130x130.png"
				  title="ETF"
				/>
			  </div>
			</div>
			<div class="col-3">
			  <div class="portfolio">
				<img
				  @click="routePortfolio"
				  src="https://arvea-dev.batix.ch/pic/184143F1D64_130x130.png"
				  title="Portfolio"
				/>
			  </div>
			</div>
		  </div>
		</div>
	  -->
      <div class="user" v-if="angemeldeterMitarbeiter">
        <svg
          id="login_icon"
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 48 48"
        >
          <g id="Layer_4" data-name="Layer 4">
            <g id="Login_Icon-2" data-name="Login_Icon">
              <path
                id="Path_3939"
                data-name="Path 3939"
                d="M100.513,69.806a9.273,9.273,0,1,1,9.273-9.273A9.273,9.273,0,0,1,100.513,69.806Zm0-16.859a7.586,7.586,0,1,0,7.586,7.586,7.586,7.586,0,0,0-7.586-7.586Z"
                transform="translate(-76.513 -42.986)"
                fill="#fff"
              />
              <path
                id="Path_3940"
                data-name="Path 3940"
                d="M24,0A24,24,0,1,0,48,24,24.027,24.027,0,0,0,24,0ZM9.685,41.192a11.434,11.434,0,0,1,1.776-5.238c2.329-3.575,6.668-5.465,12.546-5.465s10.217,1.89,12.546,5.465a11.436,11.436,0,0,1,1.776,5.238,22.342,22.342,0,0,1-28.644,0Zm30.172-1.4a13.6,13.6,0,0,0-1.9-4.939,12.533,12.533,0,0,0-4.842-4.4A19.569,19.569,0,0,0,24,28.515a19.569,19.569,0,0,0-9.115,1.937,12.533,12.533,0,0,0-4.842,4.4,13.6,13.6,0,0,0-1.9,4.939,22.388,22.388,0,1,1,31.7,0Z"
                fill="#fff"
              />
            </g>
          </g>
        </svg>
        <p>{{ angemeldeterMitarbeiter.mitarbeiter.vorname }}</p>
      </div>
      <div class="row col-4 navbar" v-if="angemeldeterMitarbeiter">
        <div class="navbar_route col-3" @click="routeCalculator">
          <p class="navbar_route_text">Calculate</p>
          <div class="navbar_route_line calculate_line" />
        </div>
        <div class="navbar_route col-3" @click="routeOptions">
          <p class="navbar_route_text">Options</p>
          <div class="navbar_route_line options_line" />
        </div>
        <div class="navbar_route col-3" @click="routeETF">
          <p class="navbar_route_text">ETF</p>
          <div class="navbar_route_line etf_line" />
        </div>
        <div class="navbar_route col-3" @click="routePortfolio">
          <p class="navbar_route_text">Portfolio</p>
          <div class="navbar_route_line portfolio_line" />
        </div>
        <!--
		  <div class="col-3">
			<p>Export</p>
			<span></span>
		  </div>-->
      </div>
      <div class="loginbar" v-if="!angemeldeterMitarbeiter">
        <h1>LOGIN</h1>
        <p>Please sign in to continue</p>
        <div class="usernameContainer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="339.336"
            height="65.977"
            viewBox="0 0 339.336 65.977"
          >
            <g
              id="Group_120"
              data-name="Group 120"
              transform="translate(17533 1175.256)"
            >
              <text
                id="Username"
                transform="translate(-17511.117 -1158.256)"
                :fill="inputColor"
                font-size="18"
                font-family="Montserrat-Medium, Montserrat"
                font-weight="500"
                letter-spacing="0.025em"
              >
                <tspan x="0" y="0">USERNAME</tspan>
              </text>
              <path
                id="Path_3951"
                data-name="Path 3951"
                d="M145.536,1H331.107c3.993,0,7.229,3.6,7.229,8.044V45.688c0,4.443-3.236,8.044-7.229,8.044H8.229C4.236,53.732,1,50.131,1,45.688V9.044C1,4.6,4.236,1,8.229,1h7.837"
                transform="translate(-17533 -1164.011)"
                fill="none"
                :stroke="inputColor"
                stroke-width="2"
              />
            </g>
          </svg>
          <input
            id="username"
            type="text"
            name="username"
            aria-describedby="username"
            autocapitalize="off"
            spellcheck="false"
            autocorrect="off"
            v-model="username"
          />
        </div>
        <div class="passwordContainer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="339.336"
            height="65.977"
            viewBox="0 0 339.336 65.977"
          >
            <g
              id="Group_121"
              data-name="Group 121"
              transform="translate(17533 1066.15)"
            >
              <path
                id="password_field"
                d="M147.624,1H331.107c3.993,0,7.229,3.6,7.229,8.044V45.688c0,4.443-3.236,8.044-7.229,8.044H8.229C4.236,53.732,1,50.131,1,45.688V9.044C1,4.6,4.236,1,8.229,1h7.837"
                transform="translate(-17533 -1054.906)"
                fill="none"
                :stroke="inputColor"
                stroke-width="2"
              />
              <text
                id="PASSWORD"
                transform="translate(-17511.117 -1049.15)"
                :fill="inputColor"
                font-size="18"
                font-family="Montserrat-Medium, Montserrat"
                font-weight="500"
                letter-spacing="0.025em"
              >
                <tspan x="0" y="0">PASSWORD</tspan>
              </text>
            </g>
          </svg>

          <input
            id="password"
            type="password"
            name="password"
            aria-describedby="password"
            autocomplete="off"
            autocapitalize="off"
            spellcheck="false"
            autocorrect="off"
            v-model="password"
          />
        </div>

        <div @click="login">SIGN IN</div>
      </div>
    </div>
  </div>
</template>

<script>
import page from "@/mixins/Page";
import store from "@/store";
import FileDownload from "js-file-download";
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import axios from "axios";
import server from "../server";

import UniverseOverview from "@/components/charts/UniverseOverview";

export default {
  name: "StartseiteArvea",
  components: {
    UniverseOverview
  },
  mixins: [page],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return "Arvea Universe Calculator";
      }
    };
  },
  data() {
    return {
      username: null,
      password: null,
      inputColor: "#fff"
    };
  },

  watch: {},

  computed: {
    isExtern() {
      if (!this.angemeldeterMitarbeiter) return;
      if (this.angemeldeterMitarbeiter.gruppen[0] === "extern") {
        // EXTERN
        this.routeETF();
        return true;
      } else return false;
    },
    loginUrl() {
      if (process.env.NODE_ENV === "development") {
        return `${server.url}/login/`;
      } else {
        return "/login/";
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    logout() {
      this.$router.replace("logout/");
    },
    routeCalculator() {
      let calculator = this.$router.resolve({ name: "Calculator" });
      window.location.href = calculator.href;
    },
    routeOptions() {
      window.location.href = this.$router.resolve({ name: "Options" }).href;
    },
    routeETF() {
      window.location.href = this.$router.resolve({ name: "ETF" }).href;
    },
    routePortfolio() {
      window.location.href = this.$router.resolve({ name: "Portfolio" }).href;
    },
    login() {
      const params = new URLSearchParams();
      params.append("username", this.username);
      params.append("password", this.password);
      axios
        .post(this.loginUrl, params)
        .then(response => {
          console.log("response log in:" + response.data);
          if (!response.data) {
            console.log("wrong password");
            this.inputColor = "red";
          } else {
            console.log("correct password");
            this.inputColor = "green";
            let startseite = this.$router.resolve({ name: "StartseiteArvea" });
            window.location.href = startseite.href;
          }
        })
        .catch(error => {
          console.log("im catch vom axios post  passwort " + error.response);
          let login = this.$router.resolve({ name: "Login" });
          window.location.href = login.href;
        });
    },
    // CALCULATOR
    fileUploaded(file) {
      this.$store.state.selectedEquities = [];
      this.listings = JSON.parse(file.xhr.response);
      this.flagFileUploaded = true;
    },
    uploadError(error) {
      this.$notify(apiErrorToAlert(error));
      this.$notify({
        type: "error",
        title: "Aktion fehlgeschlagen",
        text: "Fehler beim Hochladen der Datei. ",
        duration: 7000
      });
      this.flagFileUploaded = false;
    },

    universeBereinigen() {
      this.$refs.universeUpload.processQueue();
    },
    fileAdded() {
      this.flagFileAdded = true;
    },
    universeBerechnen() {
      this.loading = true;
      if (this.flagFileUploaded) {
        let equitiesToKeep = Array.from(
          new Set(this.$store.state.selectedEquities)
        );
        Api.put("/user-input/", equitiesToKeep, { responseType: "arraybuffer" })
          .then(
            this.$notify({
              type: "sucess",
              title: "Excel wird verarbeitet",
              text: "Bitte Warten. Dies kann einige Momente dauern",
              duration: 6000
            })
          )
          .then(response => {
            let excelFile = new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            FileDownload(excelFile, "Arvea-Universe.xlsx");
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            setTimeout(() => {
              this.$notify({
                type: "error",
                title: "Aktion fehlgeschlagen",
                text:
                  "Fehler beim Erstellen der Excel-Datei \n -> Excel-Format überprüfen. Datei erneut hochladen.",
                duration: 7000
              });
            }, 2000);
            this.loading = false;
          });

        this.$store.state.selectedEquities = [];
        //  this.resetLoading(this)
      } else {
        this.$notify({
          type: "warn",
          title: "Keine Datei hochgeladen",
          text: "Bitte Excel-Datei hochladen",
          duration: 5000
        });
        this.loading = false;
      }

      this.$bus.$emit("cleanTable");
    },

    modalConfirm() {
      $("#confirm-modal").modal("hide");

      //-> function to execute
      this.universeBerechnen();
    },
    modalCancel() {
      $("#confirm-modal").modal("hide");
    },

    schliesseConfirmModal() {
      $("#confirm-modal").modal("hide");
    },
    oeffneAlertModal() {
      $("#alert-modal").modal("show");
    },
    erstelleListenFuerModal() {
      this.selectedEquitiesAlertModal = [];
      this.notSelectedEquitiesAlertModal = [];

      this.notSelectedEquitiesAlertModal = Array.from(this.listings[0]);

      let selectedEquities = Array.from(new Set(this.selectedEquities));

      console.log(
        "equties unselected initally: " + this.notSelectedEquitiesAlertModal
      );
      console.log("selectedEquities: " + selectedEquities);

      selectedEquities.forEach(element => {
        console.log("element: " + element);

        let equity = this.notSelectedEquitiesAlertModal.filter(obj => {
          return obj.row_index === element;
        });

        console.log("equity: " + equity);

        this.selectedEquitiesAlertModal.push(equity[0]);

        const index = this.notSelectedEquitiesAlertModal.indexOf(equity[0]);

        console.log("index: " + index);
        if (index > -1) {
          this.notSelectedEquitiesAlertModal.splice(index, 1);
        }
      });

      console.log(
        "equities unselected after selection: " +
          this.notSelectedEquitiesAlertModal
      );
    }
  }
};
</script>

<style lang="scss">
.universeContainer {
  margin-left: 7%;
  background: white;
  padding: 50px;
  border-radius: 10px;
  margin-right: 7%;
  margin-bottom: 100px;
  height: 1450px;
}
body {
  background-image: url("https://arvea-dev.batix.ch/pic/17CB7957C9D_1920x1080.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  //background-position: center center;
}
#startseite .logo {
  position: relative;
  top: 0;
  width: 13vw;
  height: 17vh;
  //margin-top: 0px;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 150px;
  min-height: 100px;
}
#startseite .logo img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 75%;
  //min-height: 50%;
  transform: translate(-50%, -50%);
}
#startseite .sidebar {
  height: 100vh;
  width: 13vw;
  border-right: 1px solid white;
  position: absolute;
  top: 0;
  min-width: 150px;
}
#startseite .sidebar div {
  background-color: white;
  position: relative;
  top: 50%;
  left: 82%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 10px;
  opacity: 0.5;
}
#startseite .sidebar div:nth-child(1) {
  top: 50%;
}
#startseite .sidebar div:nth-child(2) {
  width: 18px;
  height: 18px;
  top: 50%;
  left: 81.5%;
  opacity: 1;
}
#startseite .sidebar div:nth-child(3) {
  top: 50%;
}
#startseite .textblock {
  position: absolute;
  left: 20%;
  top: 30%;
  color: white;
  width: 770px;
}
#startseite .textblock h1 {
  font-size: 75px;
  font-weight: bold;
}
#startseite .textblock p {
  font-weight: 500;
  font-size: 1rem;
}
#startseite .user {
  color: white;
  position: absolute;
  top: 5%;
  right: 0;
  overflow: hidden;
  width: 300px;
  text-transform: uppercase;
}
#startseite .user svg {
  float: left;
  margin: 10px;
  font-size: 30px;
}
#startseite .user p {
  margin: 10px;
  font-size: 20px;
  padding-top: 3px;
}
#startseite .icons div {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 50px 0px 0px 0px;
  cursor: pointer;
}
#startseite .icons div:last-child {
  margin-right: 0px;
}
#startseite .calculate,
#startseite .etf {
  margin-left: 0px;
  /*margin-right: 50px;*/
}
#startseite .portfolio {
  margin-right: 0px;
}
#startseite .navbar {
  /*width: 435px;*/
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 5%;
  left: 20%;
  border-radius: 35px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}
#startseite .navbar_route {
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  cursor: pointer;
}
#startseite .navbar_route_text {
  margin-bottom: 5px;
  padding-top: 18px;
}
#startseite .navbar_route_line {
  width: 100%;
  height: 6px;
  background: #1b2c55;
  border-radius: 6px;
  margin-top: auto;
}
#startseite .calculate_line {
  background: #1b2c55;
}
#startseite .options_line {
  //background: #9099b0;
  background: #586483;
}
#startseite .etf_line {
  //background: #c8cedf;
  background: #8e97af;
}
#startseite .portfolio_line {
  //background: #e6edff;
  background: #c5cbdc;
}
/*
  #startseite .navbar > div {
	padding-top: 7px;
	cursor: pointer;
  }
  
  #startseite .navbar > div:last-child {
	margin-right: 0px;
  }
  #startseite .navbar > div > p {
	margin-top: 5px;
	margin-bottom: 0.7rem;
  }
  #startseite .navbar > div:first-child > p {
	padding-left: 3px;
  }
  #startseite .navbar > div:nth-child(2) > p {
	padding-left: 4px;
  }
  #startseite .navbar > div:last-child > p {
	padding-left: 14px;
  }
  #startseite .navbar > div > span {
	height: 5px;
	width: 85px;
	display: block;
	border-radius: 5px;
  }
  #startseite .navbar > div:first-child > span {
	background-color: #213056;
  }
  #startseite .navbar > div:nth-child(2) > span {
	background-color: #8f98ae;
  }
  #startseite .navbar > div:last-child > span {
	background-color: #c5cbdc;
  }*/
#startseite .loginbar {
  position: absolute;
  height: 100vh;
  width: 27vw;
  min-width: 460px;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0;
  top: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
#startseite .loginbar h1 {
  margin-bottom: 0;
  margin-top: -70px;
}
#startseite .loginbar p {
  margin-bottom: 35px;
}
#startseite .loginbar > div > input {
  border: 2px solid white;
  border: none;
  margin: 5px;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border-radius: 10px;
  padding: 12px;
  width: 330px;
  font-size: 15px;
  color: white;
}
#startseite .loginbar input:focus,
textarea:focus {
  outline: none;
}
#startseite #password {
  margin-top: -10px;
}
/*
#startseite label {
  position: relative;
  font-weight: 500;
  bottom: 66px;
  right: 108px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}*/

#startseite .loginbar div:last-child {
  width: 350px;
  background-color: #213056;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  font-weight: 600;
  margin-top: 65px;
  cursor: pointer;
}

#startseite .loginbar > div {
  position: relative;
  width: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#startseite .loginbar > div > svg {
  position: absolute;
  z-index: 1;
  top: -8px;
}

#startseite .loginbar > div > input {
  border: none;
  margin: 5px;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  padding: 12px;
  width: 330px;
  font-size: 15px;
  color: white;

  position: relative;
  z-index: 2;
  padding-top: 15px;
}
#startseite .loginbar > div > input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white;
}
#startseite .passwordContainer {
  margin-top: 25px;
}

#startseite .passwordContainer > input {
  top: 15px;
}
</style>
