<template>
  <div id="LPPEvolutionGraph">
    <line-chart
      :chart-data="datacollection"
      :options="type == 'DU' ? duOptions : uPercentageOptions"
    ></line-chart>
  </div>
</template>
	  
	  <script>
import LineChart from "@/config/LineChart.js";

export default {
  name: "LPPEvolutionGraph",
  components: {
    LineChart,
  },
  mixins: [],
  props: {
    growthValue: {
      type: Array,
      required: false,
      default: null,
    },
    yMaxLPPGraphDU: {
      type: Number,
      default: 0,
    },
    yMaxLPPGraphUPercentage: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "DU",
    },
  },
  metaInfo() {
    return {};
  },
  data() {
    return {
      duOptions: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.title,
          fontSize: 25,
          fontColor: "#3e95cd",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize:
                  this.yMaxLPPGraphDU == 0
                    ? 0.5
                    : this.yMaxLPPGraphDU < 3
                    ? 0.25
                    : this.yMaxLPPGraphDU < 10
                    ? 0.5
                    : 1,
                maxTicksLimit: 40,
                max:
                  this.yMaxLPPGraphDU == 0
                    ? 3.0
                    : this.yMaxLPPGraphDU < 25
                    ? Math.ceil(this.yMaxLPPGraphDU + 1)
                    : Math.ceil(this.yMaxLPPGraphDU / 5) * 5,
                min: 0,
                callback: function (value) {
                  return value;
                },
              },
              gridLines: {
                color: "rgba(0, 0, 0, 1)",
                lineWidth: 0.5,
                zeroLineWidth: 1,
                zeroLineColor: "rgba(0, 0, 0, 1)",
              },
            },
          ],
        },
      },
      uPercentageOptions: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.title,
          fontSize: 25,
          fontColor: "#3e95cd",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 10,
                maxTicksLimit: 200,
                max: 100,
                min: 0,
                callback: function (value) {
                  return value + "%";
                },
              },
              gridLines: {
                color: "rgba(0, 0, 0, 1)",
                lineWidth: 0.5,
                zeroLineWidth: 1,
                zeroLineColor: "rgba(0, 0, 0, 1)",
              },
            },
          ],
        },
      },
      datacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            inverseData: [],
            label: this.type == "DU" ? "U/D Ratio" : "U-Percentage",
            borderColor: "#3e95cd",
            fill: false,
            datum: [],
          },
        ],
      },
    };
  },

  watch: {},
  computed: {},
  created() {
    this.setDatacollection();
  },
  mounted() {},
  methods: {
    setDatacollection() {
      if (this.type == "DU") {
        //D&U
        this.datacollection.datasets[0].data = [];
        this.datacollection.labels = [];

        this.growthValue.forEach((growthValue) => {
          this.datacollection.datasets[0].data.push(growthValue.dratioU);
          this.datacollection.labels.push(growthValue.date);
        });
      } else {
        //U-Percentage
        this.datacollection.datasets[0].data = [];
        this.datacollection.labels = [];

        this.growthValue.forEach((growthValue) => {
          this.datacollection.datasets[0].data.push(
            growthValue.dratioTotalPercent
          );
          this.datacollection.labels.push(growthValue.date);
        });
      }
    },
  },
};
</script>
	  
	  <style lang="scss">
#LPPEvolutionGraph {
  padding: 0px 40px 40px;
}
#LPPEvolutionGraph > div {
  height: 450px;
}
</style>
	