<template>
  <div class="">
    <div id="investmentClock" class="row">
      <div class="col-6 clockGraph">
        <svg class="investmentClockSVG" height="500" width="500">
          <g
            v-for="(stage, index) in stages"
            class="stage"
            :key="'stage-' + index"
          >
            <!-- Colored pie chart pieces -->
            <path
              class=""
              :fill="colors[index]"
              stroke-width="1"
              :d="getSectorPath(250, 250, null, 0, -60, 0, stage.ratio)"
            ></path>

            <!-- Path (pie chart pieces) to align the text/caption. Flips Text for D3 & U1 -->
            <path
              :id="'textCircle' + index"
              class=""
              stroke-width="1"
              fill="transparent"
              :d="
                index == 2 || index == 3
                  ? getSectorPath(250, 250, 455, 0, -60, 0)
                  : getSectorPath(250, 250, 430, -60, 0, 1)
              "
            ></path>

            <!-- text/caption -->
            <text>
              <textPath
                startOffset="42%"
                :href="'#textCircle' + index"
                class="circleText"
                :style="stage.hover ? 'font-weight: 800; fill: #1B2C55;' : ''"
              >
                {{ stage.growth_cycle }} ({{
                  Math.round(stage.ratio * 100 * 10) / 10
                }}% - {{ stage.number_of_symbols }})
              </textPath>
            </text>
          </g>

          <!-- Circle as path for hover and on click events   -->
          <path
            v-for="(stage, index) in stages"
            class="stage circleHover"
            :key="'stageP-' + index"
            :stroke="'white'"
            stroke-width="1"
            fill="transparent"
            d="m250 250 l435 0 l0 750 Z"
            @click="getEvolutions(stage)"
            @mouseover="
              stage.number_of_symbols !== 0
                ? (stage.hover = true)
                : (stage.hover = false)
            "
            @mouseleave="stage.hover = false"
          ></path>

          <!-- Circle as visual border of the pie chart -->
          <circle
            cx="250"
            cy="250"
            r="200"
            stroke="#586483"
            stroke-width="2"
            fill="none"
          />

          <!-- Short lines along the circle -->
          <path
            class="stage"
            v-for="(stage, index) in stages"
            :key="'mark-' + index"
            d="m450 250 l20 0"
            fill="#586483"
            stroke="#586483"
            stroke-width="2"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
	
	<script>
export default {
  name: "InvestmentClock",
  components: {},
  mixins: [],
  props: {
    stages: {
      type: Array,
      required: true,
    },
  },
  metaInfo() {
    return {};
  },
  data() {
    return {
      hover: false,

      colors: [
        //flat
        "#E4D1CA", //salmon
        "#C3CBD8", //mauve
        "#ABBEC4", //slate
        "#A6CCD7", //moody blue
        "#AAD2D4", //duck egg
        "#ACC8B1", //sage
        "#CDDBB7", //olive
        "#EEE9D6", //camel
        "#FFFF9F", //yellowish
        "#F7E7B3", //pale yellow
        "#CDA0CB", //purple
        "#BCC5E9", //lighter purple
      ],
    };
  },

  watch: {},

  computed: {},
  created() {},
  mounted() {
    this.setPointers();
  },
  methods: {
    setPointers() {
      let clockPointers = document.querySelectorAll(".stage");
      let pointerAngle = 360 / this.stages.length;

      clockPointers.forEach((pointer, index) => {
        let angle = pointerAngle * index;
        pointer.style.transformOrigin = "center";
        pointer.style.transform = "rotate(" + angle + "deg)";
      });
    },
    getEvolutions(name) {
      if (name.number_of_symbols !== 0) {
        this.$emit("getEvolutions", name.growth_cycle);
      } else {
        return;
      }
    },
    getSectorPath(x, y, outerDiameter, a1, a2, textflip, ratio) {
      if (!outerDiameter) {
        let pieSurface = (410 * 410 * Math.PI) / 6;
        let pieSurfaceRatio = ratio * pieSurface;

        outerDiameter = Math.sqrt((pieSurfaceRatio * 360) / (60 * Math.PI));
      }
      const degtorad = Math.PI / 180;
      const halfOuterDiameter = outerDiameter / 2;
      const cr = halfOuterDiameter - 5;
      const cx1 = Math.cos(degtorad * a2) * cr + x;
      const cy1 = -Math.sin(degtorad * a2) * cr + y;
      const cx2 = Math.cos(degtorad * a1) * cr + x;
      const cy2 = -Math.sin(degtorad * a1) * cr + y;

      return (
        "M" +
        x +
        " " +
        y +
        " " +
        cx1 +
        " " +
        cy1 +
        " A" +
        cr +
        " " +
        cr +
        " 0 0 " +
        textflip +
        cx2 +
        " " +
        cy2 +
        "Z"
      );
    },
  },
};
</script>
	
	<style lang="scss">
#investmentClock .investmentClockSVG,
#investmentClock .marks {
  transform: rotate(-90deg);
}
#investmentClock .circleText {
  font-weight: 600;
  font-size: 1.1rem;
  fill: #586483;
}

#investmentClock .circleHover {
  cursor: pointer;
}
</style>
  