<template>
  <div id="LPPEvolutionTable">
    <b-table-simple
      class="LPPEvolutionTable"
      ref="selectableTable"
      tbody-tr-class="item"
      :items="growthValues"
      hover
      sort-icon-left
      fixed
      stacked
      borderless
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th
            v-for="growthValue in growthValues"
            :key="'date-' + growthValue.date"
            >{{ growthValue.date }}</b-th
          >
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr class="totalRow">
          <b-th>TOTAL</b-th>
          <b-td
            v-for="(growthValue, index) in growthValues"
            :key="'total-' + index"
            >{{ growthValue.total }}</b-td
          >
        </b-tr>
        <b-tr class="totalRowLight">
          <b-th>D TOTAL</b-th>
          <b-td
            v-for="(growthValue, index) in growthValues"
            :key="'dtotal-' + index"
            >{{ growthValue.dtotal }}</b-td
          >
        </b-tr>
        <b-tr>
          <b-th>D1</b-th>
          <b-td
            v-for="(growthValue, index) in growthValues"
            :key="'done-' + index"
            >{{ growthValue.done }}</b-td
          >
        </b-tr>
        <b-tr>
          <b-th>D2</b-th>
          <b-td
            v-for="(growthValue, index) in growthValues"
            :key="'dtwo-' + index"
            >{{ growthValue.dtwo }}</b-td
          >
        </b-tr>
        <b-tr>
          <b-th>D3</b-th>
          <b-td
            v-for="(growthValue, index) in growthValues"
            :key="'dthree-' + index"
            >{{ growthValue.dthree }}</b-td
          >
        </b-tr>

        <b-tr class="totalRowLight">
          <b-th>U TOTAL</b-th>
          <b-td
            v-for="(growthValue, index) in growthValues"
            :key="'utotal-' + index"
            >{{ growthValue.utotal }}</b-td
          >
        </b-tr>
        <b-tr>
          <b-th>U1</b-th>
          <b-td
            v-for="(growthValue, index) in growthValues"
            :key="'uone-' + index"
            >{{ growthValue.uone }}</b-td
          >
        </b-tr>
        <b-tr>
          <b-th>U2</b-th>
          <b-td
            v-for="(growthValue, index) in growthValues"
            :key="'utwo-' + index"
            >{{ growthValue.utwo }}</b-td
          >
        </b-tr>
        <b-tr>
          <b-th>U3</b-th>
          <b-td
            v-for="(growthValue, index) in growthValues"
            :key="'uthree-' + index"
            >{{ growthValue.uthree }}</b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
	  
<script>
export default {
  name: "LPPEvolutionTable",
  components: {},
  mixins: [],
  props: {
    growthValues: {
      type: Array,
      required: true,
    },
  },
  metaInfo() {
    return {};
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
	  
	  <style lang="scss">
#LPPEvolutionTable .LPPEvolutionTable td {
  text-align: center;
}
#LPPEvolutionTable .LPPEvolutionTable td,
#LPPEvolutionTable .LPPEvolutionTable th:not(:first-child) {
  text-align: center;
}
#LPPEvolutionTable .LPPEvolutionTable thead th {
  border-bottom: 3px solid #1b2c55;
}
#LPPEvolutionTable .totalRow {
  background: #a6ccd7;
}
#LPPEvolutionTable .totalRowLight {
  background: #a6ccd76b;
}
</style>
	