<template>
  <div class="LPPEvolutionsPerGrowthCycle p-3">
    <div class="goBack mb-5 d-flex align-items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-chevron-left"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
        />
      </svg>
      <h4 class="m-0" @click="hideEvolutions">Overview</h4>
    </div>
    <div class="mb-3 d-flex align-items-center">
      <h4 class="mr-4 my-auto">Filter by Symbol:</h4>
      <div class="form-group p-0 m-0 mr-2">
        <input
          class="filterInput"
          v-model="filterSymbol"
          @keyup.enter="filterTable"
        />
      </div>
      <div class="btn btn-primary mr-2" @click="filterTable">Go</div>
      <div class="btn btn-secondary mr-2" @click="reset">Reset</div>
      <div class="form-group col-2 p-0 m-0 mr-2 selectedGrowthCycle">
        <v-select
          v-model="selectedGrowthCycle"
          label="name"
          placeholder="Growthcycle"
          :options="growthCycleIDs"
          name="growthcycle"
          :clearable="true"
        >
        </v-select>
      </div>
    </div>

    <div
      class="spinnerRow row d-flex justify-content-center align-items-center"
      v-if="tableLoading"
    >
      <b-spinner class="lppSpinner"></b-spinner>
    </div>
    <div v-if="!tableLoading">
      <b-table
        class="LPPEvolutionsPerGrowthCycleTbl"
        style="overflowy: scroll"
        ref="LPPEvolutionsPerGrowthCycleTbl"
        tbody-tr-class="item"
        :items="listData ? listData : []"
        :fields="fields"
        striped
        fixed
        borderless
        v-show="listData.length !== 0"
      >
        <!-- <template #cell(strikeevolution)="row">
        <div
          class="ml-5 goBack"
          role="button"
          @click="showStrikeEvolutions(row.item)"
        >
          see strikes
        </div>
      </template> -->

        <template #cell(show_details)="row">
          <!-- <b-button size="sm" @click="showStrikeEvolutions(row)" class="goBack">
          {{ row.detailsShowing ? "Hide" : "Show" }} strike evolution
        </b-button> -->
          <button
            @click="showStrikeEvolutions(row)"
            class="button_strikeevolution"
          >
            {{ row.detailsShowing ? "Hide" : "Show" }} data
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g id="expand_circle_down" transform="translate(9490 -6637)">
                <rect
                  id="frame_24x24"
                  width="24"
                  height="24"
                  transform="translate(-9490 6637)"
                  fill="none"
                />
                <path
                  id="expand_circle_down-2"
                  data-name="expand_circle_down"
                  d="M15.421,18.837a.839.839,0,0,0,1.158,0L20.2,15.218a.607.607,0,0,0,.174-.449.564.564,0,0,0-.2-.449.631.631,0,0,0-.926,0L16,17.563l-3.271-3.271a.524.524,0,0,0-.449-.188.776.776,0,0,0-.478.188.7.7,0,0,0,0,.926ZM16,27a11.124,11.124,0,0,1-4.328-.839,10.675,10.675,0,0,1-5.833-5.833A11.124,11.124,0,0,1,5,16a11.05,11.05,0,0,1,.839-4.3A10.753,10.753,0,0,1,8.17,8.2,10.988,10.988,0,0,1,16,5,10.921,10.921,0,0,1,27,16a10.988,10.988,0,0,1-3.2,7.83,10.794,10.794,0,0,1-3.488,2.33A11.018,11.018,0,0,1,16,27ZM16,16Zm0,9.7A9.542,9.542,0,0,0,25.7,16,9.542,9.542,0,0,0,16,6.3,9.542,9.542,0,0,0,6.3,16,9.542,9.542,0,0,0,16,25.7Z"
                  transform="translate(-9494 6633)"
                />
              </g>
            </svg>
          </button>
        </template>

        <template #row-details="row">
          <div
            id="LPPEvolutionsPerGrowthCycleTbl"
            class="spinnerRow row d-flex justify-content-center align-items-center"
            v-if="isBusy"
          >
            <b-spinner class="lppSpinner"></b-spinner>
          </div>
          <div>
            <strike-evolution-graph
              :title="'Strike Evolution - ' + row.item.symbol_name"
              type="SE"
              :yMaxStrikePrice="yMaxStrikePrice"
              :yMinStrikePrice="yMinStrikePrice"
              :strikeEvolution="strikes"
              :key="'SEGraph-' + updateKey"
            ></strike-evolution-graph>
          </div>
        </template>
      </b-table>
    </div>
    <div v-if="listData.length === 0 && filterSymbol" class="noRows">
      Es wurden keine Symbole unter der Eingabe "{{ this.filterSymbol }}"
      gefunden.
    </div>
  </div>
</template>
		
		<script>
//import { BSpinner } from "bootstrap-vue";
import Arvea from "@/Arvea";
import StrikeEvolutionGraph from "@/components/charts/StrikeEvolutionGraph";
import { BSpinner } from "bootstrap-vue";
import Api from "@/Api";

export default {
  name: "LPPEvolutionsPerGrowthCycle",
  components: {
    BSpinner,
    StrikeEvolutionGraph,
  },
  mixins: [],
  props: {
    evolutionPerGrowthCycle: {
      type: Array,
      required: true,
    },
    growthCycleIDs: {
      type: Array,
      required: true,
    },
    growthCycleName: {
      type: String,
      required: false,
    },
    tableLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  metaInfo() {
    return {};
  },
  data() {
    return {
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      filterSymbol: "",
      initialListData: null,
      listData: null,
      filter: "",
      selectedGrowthCycle: this.growthCycleName,
      fields: [
        { key: "symbol_name", label: "Symbol" },
        {
          key: "growth_cycles[6].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[6].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[5].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[5].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[4].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[4].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[3].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[3].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[2].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[2].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[1].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[1].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "growth_cycles[0].growth_cyle",
          label: `${this.evolutionPerGrowthCycle[0].growth_cycles[0].date}`,
          tdClass: (value) => {
            return this.getColor(value);
          },
        },
        {
          key: "show_details",
          sortable: false,
          label: this.$t("Strike Evolution"),
          tdClass: "align-items-center",
        },
      ],
      strikes: [],
      yMaxStrikePrice: 0,
      yMinStrikePrice: 0,
      updateKey: "",
      counter: 0,
    };
  },
  watch: {
    selectedGrowthCycle() {
      this.$emit("growthCycleChanged", this.selectedGrowthCycle);
    },
    evolutionPerGrowthCycle() {
      this.initialListData = this.evolutionPerGrowthCycle;
      this.filterTable();
    },
  },
  computed: {},
  created() {
    this.initialListData = this.evolutionPerGrowthCycle;
    this.filterTable();
  },
  mounted() {},
  methods: {
    getColor(value) {
      if (value.includes("D")) {
        return "table-success";
      } else {
        return "table-danger";
      }
    },
    hideEvolutions() {
      if (!this.tableLoading) {
        this.$emit("hideEvolutions");
      }
    },

    filterTable() {
      this.filter = this.filterSymbol;

      if (this.filter && this.filter !== "") {
        this.listData = this.initialListData.filter((data) => {
          return data.symbol_name
            .toLowerCase()
            .trim()
            .includes(this.filter.toLowerCase());
        });
      } else {
        this.listData = this.initialListData;
      }
    },
    reset() {
      this.listData = this.initialListData;
      this.filter = "";
      this.filterSymbol = "";
    },
    showStrikeEvolutions(row) {
      this.isBusy = true;
      this.strikes = [];
      this.toggleDetails(row);

      if (row.item._showDetails) {
        // jeweils das Datum vom Vortag (nur MO - FR)
        let lastWeekday = new Date();
        lastWeekday.setDate(
          lastWeekday.getDate() -
            (lastWeekday.getDay() === 6 ? 2 : lastWeekday.getDay() > 1 ? 1 : 3) // 3 Tage zurück wenn MO, 2 Tage zurück für SO, ansonsten 1
        );
        lastWeekday.toDateString();

        // Format yyyy-MM-dd
        let dateOfLastWeekday = [
          lastWeekday.getFullYear(),
          ("0" + (lastWeekday.getMonth() + 1)).slice(-2),
          ("0" + lastWeekday.getDate()).slice(-2),
        ].join("-");

        Api.get("strikeevolution/", {
          params: { id: row.item.symbol_id, date: dateOfLastWeekday },
        })
          .then((response) => {
            this.strikes = response.data.strikeEvolutions;
            this.setYMaxAndMinStrikePrice();
            this.counter++;
            this.updateKey =
              response.data.strikeEvolutions[0].symbol + `${this.counter}`;
          })
          .catch(() => {})
          .finally(() => {
            this.isBusy = false;
          });
      }
    },
    toggleDetails(row) {
      if (row.item._showDetails) {
        this.$set(row.item, "_showDetails", false);
      } else {
        this.evolutionPerGrowthCycle.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });

        this.$set(row.item, "_showDetails", true);
      }
    },
    setYMaxAndMinStrikePrice() {
      let maxSP = 0;
      let minSP = 99999;

      this.strikes.forEach((strike) => {
        if (strike.volume && strike.oi) {
          if (strike.volume > maxSP || strike.oi > maxSP) {
            maxSP = strike.volume > strike.oi ? strike.volume : strike.oi;
          }
          if (strike.volume < minSP || strike.oi < minSP) {
            minSP = strike.volume < strike.oi ? strike.volume : strike.oi;
          }
        }
      });
      this.yMaxStrikePrice = Math.ceil(maxSP * 1.1);
      this.yMinStrikePrice = Math.floor(minSP);
    },
  },
};
</script>
		
		<style lang="scss">
.LPPEvolutionsPerGrowthCycle {
  width: 100%;
  min-height: 300px;
}
.goBack {
  cursor: pointer;
  width: fit-content;
}
.goBack:hover {
  color: #a6ccd7;
}
.filterInput {
  height: 38px;
}
#LPPEvolutionsPerGrowthCycleTbl .spinnerRow {
  min-height: 300px;
}
#LPPEvolutionsPerGrowthCycleTbl .lppSpinner {
  width: 4rem;
  height: 4rem;
}
.button_strikeevolution {
  border: none;
  background: transparent;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 7px;
  font-size: 0.813rem;
  padding: 5px;
  color: #000000;
  cursor: pointer;
}
.button_strikeevolution:hover {
  color: #858d8f;
}
.button_strikeevolution svg {
  font-size: 0.9rem;
  rotate: 0deg;
  transition: rotate 100ms ease-in-out;
}
tr.b-table-has-details .button_strikeevolution svg {
  rotate: 180deg;
}
tr.item > td:first-of-type {
  font-weight: 600;
  font-size: 0.875rem;
  vertical-align: center;
}
.noRows {
  color: rgb(209, 53, 53);
}
.selectedGrowthCycle .vs__dropdown-toggle {
  height: 38px;
}
</style>
	  