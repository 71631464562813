<template>
  <div class="universeOverview p-3">
    <div class="row ml-1 mb-5 welcomeTitle">
      <h2>Welcome to the ARVEA Universe</h2>
    </div>
    <div class="row px-3 mb-4">
      <h3>LPP Evolution</h3>
    </div>
    <div
      class="row px-3 mb-2 d-flex align-items-center universeSelection"
      v-if="!showEvolutions"
    >
      <h5>Select your universe:</h5>
      <div class="col-2 form-group dropdown">
        <v-select
          v-model="selectedUniverse"
          label="name"
          placeholder="Universe"
          :options="universes"
          name="universes"
          :clearable="false"
        >
          <span slot="no-options"> Keine Optionen </span>
        </v-select>
        <label for="universes">Universes</label>
      </div>
      <div class="col-1 form-group dropdown">
        <v-select
          v-model="interval"
          label="name"
          placeholder="Interval"
          :options="intervalOptions"
          name="interval"
          :clearable="false"
          :reduce="(iVal) => iVal.value"
        >
          <span slot="no-options"> Keine Optionen </span>
        </v-select>
        <label for="interval">Interval</label>
      </div>
      <div class="col-2 pl-0 form-group">
        <div class="graphRangeLinks">
          <p
            class="graphRangeLink px-2"
            :style="range.selected ? 'color: #3e95cd;' : ''"
            v-for="(range, index) in graphRanges"
            :key="index"
            @click="setSelectedGraphRange(range)"
          >
            {{ range.range }}
          </p>
        </div>
      </div>
      <div class="universeTitle ml-auto">
        <h1>
          {{ this.selectedUniverse.name }} - {{ this.maxSymbols }} Symbols
        </h1>
      </div>
    </div>

    <div
      class="row px-3 mb-2 d-flex align-items-center universeSelection"
      v-if="showEvolutions"
    >
      <div class="universeTitle ml-auto">
        <h1 v-if="growthCycleName">
          {{ this.growthCycleName }} - {{ this.selectedUniverse.name }} -
          {{ this.selectedGrowthCycleTotalSymbols }} Symbols
        </h1>
        <h1 v-else>
          {{ this.selectedUniverse.name }} -
          {{ this.selectedGrowthCycleTotalSymbols }} Symbols
        </h1>
      </div>
    </div>

    <div
      class="spinnerRow row d-flex justify-content-center align-items-center"
      v-if="isBusy && !showEvolutions"
    >
      <b-spinner class="lppSpinner"></b-spinner>
    </div>
    <div class="row" v-if="!isBusy && !showEvolutions">
      <l-p-p-evolution-graph
        class="col-6"
        :growthValue="growthValuesChart"
        :yMaxLPPGraphDU="yMaxLPPGraphDU"
        :title="'U&D'"
        :type="'DU'"
        :key="'DU-' + updateKey"
      />
      <investment-clock
        class="col-6 d-flex justify-content-center align-items-center"
        :stages="clockData"
        :key="'clockData-' + updateKey"
        @getEvolutions="getEvolutions"
      />
      <l-p-p-evolution-graph
        class="col-6"
        :growthValue="growthValuesChart"
        :yMaxLPPGraphUPercentage="yMaxLPPGraphUPercentage"
        :title="'U-Percentage'"
        :type="'U-Percentage'"
        :key="'UPercentage-' + updateKey"
      />
      <l-p-p-evolution-table
        class="col-6"
        :growthValues="growthValues"
        :key="'LPPTable-' + updateKey"
      />
    </div>
    <div class="row evolutionsPerGrowthCycle" v-if="showEvolutions">
      <l-p-p-evolutions-per-growth-cycle
        :evolutionPerGrowthCycle="evolutionPerGrowthCycle"
        @hideEvolutions="showEvolutions = false"
        :growthCycleIDs="growthCycleIDs"
        @growthCycleChanged="getChangedEvolutions"
        :growthCycleName="growthCycleName"
        :tableLoading="isBusy"
      />
    </div>
  </div>
</template>

	  <script>
import Arvea from "@/Arvea";
import Api from "@/Api";
//import { apiErrorToAlert } from "@/utils/Errorhandler";
//import axios from "axios";
//import server from "@/server";
import { BSpinner } from "bootstrap-vue";

import InvestmentClock from "@/components/charts/InvestmentClock";
import LPPEvolutionGraph from "@/components/charts/LPPEvolutionGraph";
import LPPEvolutionTable from "@/components/charts/LPPEvolutionTable";
import LPPEvolutionsPerGrowthCycle from "@/components/charts/LPPEvolutionsPerGrowthCycle";

export default {
  name: "UniverseOverview",
  components: {
    LPPEvolutionGraph,
    InvestmentClock,
    LPPEvolutionTable,
    LPPEvolutionsPerGrowthCycle,
    BSpinner,
  },
  mixins: [],
  metaInfo() {
    return {};
  },
  data() {
    return {
      defaultUniverseID: "c31b4762-ea57-4d8b-99c9-599ec576bdf3", //US
      isBusy: false,
      ladeFehler: false,
      universes: [],
      interval: 0,
      intervalOptions: [
        {
          name: "Daily",
          value: 0,
        },
        {
          name: "Weekly",
          value: 5,
        },
      ],
      selectedUniverse: {},
      lppEvolution: [],
      //clockStages: [],
      growthValues: [],
      growthValuesChart: [],
      clockData: [],
      maxSymbols: 0,
      yMaxLPPGraphDU: 0,
      yMaxLPPGraphUPercentage: 0,
      updateKey: "",
      showEvolutions: false,
      growthCycleName: "",
      growthCycleIDs: [
        { name: "D1", id: "1a3207cf-76d5-4c6d-a085-15f55fec8717" },
        { name: "D2", id: "28ab95f9-a192-428f-ba8e-d7ccb7f6c32e" },
        { name: "D3", id: "75c3cf19-b88c-4066-99fa-0ab5c940335d" },
        { name: "U1", id: "6e486964-f8ec-479c-bad5-0fe5f1e82594" },
        { name: "U2", id: "2629efec-efac-4557-bef3-9ecba19407d0" },
        { name: "U3", id: "41bac89d-52b0-4b8d-829d-ecdbe0ae5149" },
      ],
      evolutionPerGrowthCycle: [],
      selectedGrowthCycleTotalSymbols: null,
      graphRanges: [
        {
          range: "3M",
          selected: false,
        },
        {
          range: "6M",
          selected: false,
        },
        {
          range: "12M",
          selected: false,
        },
      ],
      selectedGraphRange: {
        range: "default",
        selected: true,
      },
    };
  },

  watch: {
    selectedUniverse: function () {
      this.getUniverseData();
    },

    interval: function () {
      this.getUniverseData();
    },
  },

  computed: {},
  created() {
    this.selectedUniverse = { id: this.defaultUniverseID, name: "US" };
    this.getSelectData();
  },
  mounted() {},
  methods: {
    getSelectData() {
      Api.get("/portfolio/select-data/").then((response) => {
        this.universes = response.data.universes;
      });
    },
    getUniverseData() {
      this.isBusy = true;
      Api.get("lppevolution/", {
        params: {
          universeId: this.selectedUniverse.id,
          interval: this.interval,
          range: this.selectedGraphRange?.range,
        },
      })
        .then((response) => {
          this.ladeFehler = false;
          this.maxSymbols = response.data.total_symbol;
          this.lppEvolution = response.data.lpp_evolutions;
          this.growthValues = response.data.growth_value;
          this.growthValuesChart = [...this.growthValues].reverse();
          this.clockData = response.data.clock_cycles;
          this.setYMax();
          this.updateKey = response.data.universe_id + this.interval; //Wird als Key für die Graphen benötigt, damit diese mit den neuen Daten neu gerendert werden
        })
        .catch(() => {
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    setYMax() {
      let maxDU = 0;
      let maxUPercentage = 0;

      this.growthValues.forEach((growthValue) => {
        if (growthValue.dratioU && growthValue.dratioU > maxDU) {
          maxDU = growthValue.dratioU;
        }

        if (
          growthValue.dratioTotalPercent &&
          growthValue.dratioTotalPercent > maxDU
        ) {
          maxUPercentage = growthValue.dratioTotalPercent;
        }
      });

      this.yMaxLPPGraphDU = Math.ceil(maxDU / 0.5) * 0.5;
      this.yMaxLPPGraphIPercentage = Math.ceil(maxUPercentage / 10) * 10;
    },
    getEvolutions(name) {
      this.isBusy = true;
      let growthCycleID = this.growthCycleIDs.find(
        (cycle) => cycle.name.toLowerCase() == name.toLowerCase()
      ).id;

      this.growthCycleName = name;

      Api.get("lppevolutions/", {
        params: {
          universeId: this.selectedUniverse.id,
          growthCycleId: growthCycleID,
        },
      })
        .then((response) => {
          this.evolutionPerGrowthCycle = response.data.lpp_evolutions;
          this.selectedGrowthCycleTotalSymbols = response.data.total_symbol;
        })
        .catch(() => {
          //this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
          this.showEvolutions = true;
        });
    },
    getChangedEvolutions(growthCycle) {
      this.isBusy = true;
      this.growthCycleName = growthCycle?.name;

      Api.get("lppevolutions/", {
        params: {
          universeId: this.selectedUniverse.id,
          growthCycleId: growthCycle?.id,
        },
      })
        .then((response) => {
          this.evolutionPerGrowthCycle = response.data.lpp_evolutions;
          this.selectedGrowthCycleTotalSymbols = response.data.total_symbol;
        })
        .catch(() => {
          //this.ladeFehler = true;
        })
        .finally(() => {
          this.showEvolutions = true;
          this.isBusy = false;
        });
    },
    setSelectedGraphRange(range) {
      const otherRanges = this.graphRanges.filter((graphRange) => {
        return graphRange.range !== range.range;
      });
      otherRanges.forEach((other) => {
        other.selected = false;
      });
      range.selected = !range.selected;
      if (range.selected) {
        this.selectedGraphRange = range;
      } else {
        this.selectedGraphRange = {
          range: "default",
          selected: true,
        };
      }

      this.getUniverseData();
    },
  },
};
</script>

	  <style lang="scss">
.welcomeTitle {
  border-bottom: 3px solid #1b2c55;
  padding-bottom: 15px;
}
.universeOverview .dropdown {
  position: relative;
  padding-left: 0;
}
.universeOverview .dropdown label {
  position: absolute;
  left: 0;
  height: 100%;
}
.universeOverview .graphRangeLinks {
  position: relative;
  text-align: left;
  margin-right: 3.2rem;
  z-index: 1;
}
.universeOverview .graphRangeLink {
  display: inline-flex;
  color: #586483;
  font-weight: bold;
  margin-bottom: 0;
}
.universeOverview .graphRangeLink:hover {
  color: #3e95cd;
  cursor: pointer;
}

.universeSelection {
  position: relative;
}
.universeSelection h5 {
  padding-top: 16px;
  margin-right: 25px;
}
.universeTitle {
  position: absolute;
  right: 0;
  background: linear-gradient(to left, #1b2c55, white);
  color: white;
  font-style: italic;
  padding: 13px;
  padding-right: 13px;
  width: 50%;
  text-align: right;
  padding-right: 35px;
  z-index: 0;
}
.universeTitle h1 {
  margin: 0;
}
.investmentClockSVG,
.marks {
  transform: rotate(-90deg);
}
.circleText {
  font-weight: 600;
  font-size: 1.1rem;
  fill: #586483;
}

.circleHover {
  cursor: pointer;
}
.spinnerRow {
  min-height: 750px;
}
.lppSpinner {
  width: 7rem;
  height: 7rem;
}
.evolutionsPerGrowthCycle {
  overflow-y: scroll;
  max-height: 1050px;
  margin-top: 50px;
  scrollbar-color: #1b2c55 #c5cbdc;
  scrollbar-width: thin;
}
</style>
